<template>
  <div>That's a 404. Try going <trans-link to="/">Home</trans-link></div>
</template>


<script>

export default {
  name: "PageStatus404"
};
</script>
